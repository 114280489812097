<template>
  <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
    <!-- <a-form-item label="快捷使用：" v-if="isSeller && paBankInfo">
      <a-row :gutter="16">
        <a-col :span="10">
          <div class="fast-chunk" :class="entitySelect && 'fast-active'" @click="fastChange">
            <p>{{paBankInfo.bankName}}</p>
            <p>{{paBankInfo.bankAccount}}</p>
          </div>
        </a-col>
      </a-row>
    </a-form-item> -->
    <a-form-item label="快捷使用：" v-if="entityBank">
      <a-row :gutter="16">
        <a-col :span="10" v-for="(item,index) in entityBank" :key="index">
          <div class="fast-chunk" :class="entityIndex === index && 'fast-active'" @click="fastChangeP(item, index)">
            <p>{{item.bankName}}</p>
            <p>{{item.bankAccount}}</p>
          </div>
        </a-col>
      </a-row>
    </a-form-item>
    <a-form-item label="账号类型：" v-if="isSeller">
      <a-row>
        <a-col>
          <a-space :size="24">
            <a-select v-model="form.isPublic" style="width: 170px" placeholder="请选择账号类型">
              <a-select-option :key="0"> 对私 </a-select-option>
              <a-select-option :key="1"> 对公 </a-select-option>
            </a-select>
            <span class="font-error">* 个人卡则选择对私，公司卡选对公</span>
          </a-space>
        </a-col>
      </a-row>
    </a-form-item>
    <a-form-item label="法人身份证：" v-if="!isSeller">
      <a-row>
        <a-col>
          <a-input v-model="form.legalIdCard" placeholder="请输入法人身份证号码"/>
        </a-col>
      </a-row>
    </a-form-item>
    <a-form-item label="银行账号：">
      <a-row>
        <a-col>
          <a-input v-model="form.bankAccount" @change="accountChange"
            @input="form.bankAccount=$event.target.value.replace(/[^\d]/g,'')" @blur="blur(form.bankAccount)"
            placeholder="请输入银行账号" />
        </a-col>
      </a-row>
    </a-form-item>
    <a-form-item label="开户行名称：">
      <a-row>
        <a-col>
          <a-select showSearch style="width: 100%" placeholder="请输入银行关键字" v-model="form.eiconBankBranchId"
            :filter-option="filterOption"
            @search="searchList"
            @change="form.eiconBankBranchId = $event;form.bankName = paBankList.find(item => item.eiconBankBranchId === $event).bankNameSec">
            <a-select-option v-for="item in paBankList" :key="item.eiconBankBranchId"> {{ item.bankNameSec }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </a-form-item>
    <a-form-item label="超级网银行号：">
      <a-row>
        <a-col>
          <a-input :value="form.eiconBankBranchId" disabled placeholder="查询后自动输入" />
        </a-col>
      </a-row>
    </a-form-item>
    <a-form-item label="预留手机号">
      <a-row>
        <a-col>
          <a-input v-model="form.phone" :maxLength="11" @input="form.phone=$event.target.value.replace(/[^\d]/g,'')"
            placeholder="请输入预留手机号" />
        </a-col>
      </a-row>
    </a-form-item>

    <div class="foot">
      <a-button type="primary" size="large" @click="bankAuth" :loading="authLoading">银行鉴权</a-button>
    </div>

    <paAAA ref="paAAA" :isSeller="isSeller" @ok="$emit('ok')" :isPublic="isSeller ? form.isPublic : 1 "/>
  </a-form>
</template>

<script>
import paAAA from '@/components/paBind/paAAA'
import { banksList, supplierBind, purchaserBind } from '@/api/pa'
import bank from '@/utils/banks'
export default {
  props: {
    isSeller: {
      type: Boolean,
      default: false
    },
    entityBank: {
      type: Array,
      default: () => undefined
    },
    paBankInfo: {
      type: Object,
      default: () => undefined
    }
  },
  components: {
    // SearchSelect
    paAAA
  },
  data() {
    return {
      entityIndex: undefined,
      entitySelect: false,
      authLoading: false,
      Bank: [], // 平安银行列表
      paBankList: [], // 平安银行列表
      form: {
        isPublic: 0, // 是否是公户(0 私户，1公户)
        bankName: undefined, // 银行名称
        bankAccount: undefined, // 银行账号
        eiconBankBranchId: undefined, // 超级网银号
        phone: undefined, // 手机号
        supplierId: this.$route.query.entityId,
        purchaserId: this.$route.query.entityId,
        purchaserBankAccountId: undefined, // 快捷选定的采购商银行卡id
        legalIdCard: undefined, // 法人身份证号码
      },
    }
  },
  // watch: {
  //   paBankInfo(newVal) {
  //     if (newVal && newVal.bankName) {
  //       this.Bank.forEach(item => {
  //         item.bankNameSec === newVal.bankName && (this.form.eiconBankBranchId = item.eiconBankBranchId,this.form.bankName = newVal.bankName)
  //       })
  //     }
  //   }
  // },
  created() {
    // 获取所有开户行
    banksList().then(res => {
      if (res.code === 200) {
        this.Bank = res.data
      } else {
        this.$message.error(res.message)
      }
    })
  },
  methods: {
    toAAA () {
      this.$refs.paAAA.visible = true
    },
    // 重置数据
    reset() {
      this.entityIndex = undefined,
      this.entitySelect = false,
      this.authLoading = false,
      this.form = {
        isPublic: 0, // 是否是公户(0 私户，1公户)
        bankName: undefined, // 银行名称
        bankAccount: undefined, // 银行账号
        eiconBankBranchId: undefined, // 超级网银号
        phone: undefined, // 手机号
        supplierId: this.$route.query.entityId,
        purchaserId: this.$route.query.entityId,
        purchaserBankAccountId: undefined, // 快捷选定的采购商银行卡id
        legalIdCard: undefined, // 法人身份证号码
      }
    },
    // 搜索开户行
    searchList (search) {
      if (!search) return
      banksList({search}).then(res => {
        if (res.code === 200) {
          this.paBankList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 失焦获取银行
    blur(e) {
      const bankName = bank(e).bankName
      console.log(bankName);
      if (bankName) {
        // this.Bank.forEach((item) => {
        //   if (item.bankNameSec === bankName) {
        //     this.form.eiconBankBranchId = item.eiconBankBranchId
        //     this.form.bankName = bankName
        //     this.paBankList = [item]
        //   }
        // })
        for (let index = 0; index < this.Bank.length; index++) {
          const item = this.Bank[index];
          if (item.bankNameSec === bankName) {
            this.form.eiconBankBranchId = item.eiconBankBranchId
            this.form.bankName = bankName
            this.paBankList = [item]
            return
          } else {
            this.form.eiconBankBranchId = undefined
            this.form.bankName = undefined
            this.paBankList = []
          }
        }
      } else {
        this.form.bankName = undefined
        this.form.eiconBankBranchId = undefined
        this.paBankList = []
      }
    },
    // 筛选
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 
    // fastChange() {
    //   this.entitySelect = !this.entitySelect
    //   if (this.entitySelect) {
    //     this.form.bankAccount = this.paBankInfo.bankAccount
    //     this.Bank.forEach(item => {
    //       item.bankNameSec === this.form.bankAccount && (this.form.eiconBankBranchId = item.eiconBankBranchId, this.form.bankName = this.paBankInfo.bankName)
    //     })
    //   }
    // },
    // 买家选择
    fastChangeP(i, index) {
      if (this.entityIndex === index) {
        this.entityIndex = undefined
        this.form.purchaserBankAccountId = undefined
        return
      }
      this.entityIndex = index
      this.form.purchaserBankAccountId = i.id
      this.form.bankAccount = i.bankAccount
      this.blur(this.form.bankAccount)
    },
    // 开户行号改动时 移除快捷使用的样式
    accountChange() {
      this.entityIndex = undefined
      this.form.purchaserBankAccountId = undefined
      this.entitySelect = false
    },
    // 银行鉴权
    bankAuth() {
      console.log(this.form);
      if (!this.isSeller && !this.form.legalIdCard) {
        this.$message.error('请填写法人身份证号码')
        return
      } else if (!this.form.bankName) {
        this.$message.error('请选择开户行')
        return
      } else if (!this.form.bankAccount) {
        this.$message.error('请填写银行卡账号')
        return
      } else if (!this.form.eiconBankBranchId) {
        this.$message.error('请选择开户行')
        return
      } else if (!this.form.phone) {
        this.$message.error('请填写预留手机号')
        return
      }
      this.authLoading = true
      if (this.isSeller) {
        supplierBind(this.form).then(res => {
          this.authLoading = false
          if (res.code === 200) {
            console.log(res);
            this.$message.success(res.message)
            this.$refs.paAAA.visible = true
            this.$emit('ok')
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        purchaserBind(this.form).then(res => {
          this.authLoading = false
          if (res.code === 200) {
            console.log(res);
            this.$message.success(res.message)
            this.$refs.paAAA.visible = true
            this.$emit('ok')
          } else {
            this.$message.error(res.message)
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.foot {
  margin-top: 24px;
  text-align: center;
  button {
    width: 150px;
    border-radius: 5px;
  }
}
.fast-chunk {
  padding: 8px;
  background-color: rgba(242, 242, 242, 1);
  border-radius: 5px;
  font-size: 12px !important;
  margin-bottom: 8px;
  color: #d7d7d7;
  cursor: pointer;
  p {
    line-height: initial;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 700;
  }
}
.fast-active {
  color: #fff;
  background: rgba(91, 176, 255, 1);
}
.font-error {
  color: @error;
}
/deep/ .ant-form-item {
  margin-bottom: 16px;
}
</style>