<template>
  <a-modal :width="500" :centered="true" :visible="visible" footer="" @cancel="visible=false">
    <a-row>
      <a-col :span="24" align="center">
        <span class="authentication money icon-m"></span>
      </a-col>
      <a-col :span="24" align='center'> <span class="title">小额鉴权</span> </a-col>
      <a-col :span="24" class="text-space" style="text-align: center">
        已向您申请绑定的账号中转入小于等于0.5元的随机金额，并短信通知，请在以下金额核对框内输入具体金额，完成最终鉴权！</a-col>
      <a-col :span="24" class="text-space-max" align="center" style="padding-left: 32px;" v-if="isPublic">收到金额：
        <a-input-number :precision="2" style="width:160px" v-model="form.amount" placeholder="请输入"/> 元
      </a-col>
      <a-col :span="24" class="text-space" align="center">鉴权序列号：
        <a-input style="width:160px" v-model="form.code" placeholder="请输入"/>
      </a-col>
      <a-col :span="24" class="text-space">
        <a-alert show-icon style="font-size:12px" message="若不确定金额，可关闭此页面，事后前往买家中心-财务管理进行鉴权。" type="info" closable />
      </a-col>
      <a-col class="text-space-max" :span="24" align="center">
        <a-button type="primary" class="btn" :loading="loading" @click="auth">确认</a-button>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { supplierAuth, purchaserAuth } from '@/api/pa'
export default {
  props: {
    isSeller: {
      type: Boolean,
      default: false
    },
    isPublic: {
      type: Number,
      default: 1
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        this.form = {
          purchaserId: this.$route.query.entityId,
          supplierId: this.$route.query.entityId,
          code: undefined,
          amount: undefined
        }
      }
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      form: {
        purchaserId: this.$route.query.entityId,
        supplierId: this.$route.query.entityId,
        code: undefined,
        amount: undefined
      }
    }
  },
  methods: {
    auth() {
      if (!this.form.amount && this.isPublic) {
        this.$message.error('请输入收到的金额')
        return
      } else if (!this.form.code) {
        this.$message.error('请输入鉴权序列号')
        return
      } 
      this.loading = true
      if (this.isSeller) {
        supplierAuth(this.form).then(res => {
          this.loading = false
          if (res.code === 200) {
            console.log(res);
            this.visible = false
            this.$emit('ok')
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        purchaserAuth(this.form).then(res => {
          this.loading = false
          if (res.code === 200) {
            console.log(res);
            this.visible = false
            this.$emit('ok')
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.icon-m {
  color: rgb(109, 168, 86);
  font-weight: 700;
  font-size: 60px;
}
.title {
  font-size: 24px;
  color: #000;
  font-weight: 650;
}
.text-space {
  margin-top: 20px;
}
.text-space-max {
  margin-top: 30px;
}
.btn {
  font-size: 18px;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: rgb(109, 186, 68);
}
</style>