<template>
  <div class="bankinformation">
    <div class="mainbody">
      <div class="header-title">
        <p>买家入驻-银行信息</p>
      </div>
      <div class="header-text">
        <p>
          平台默认支持浙商网关支付，也可以同时申请浙商银行和平安银行的余额支付模式。请自行选择！
        </p>
      </div>
      <!-- 网关支付 -->
      <a-row type="flex" justify="center">
        <div class="gateway-pay">
          <div class="gateway-title">
            <span class="big-title">网关支付</span>
            <span class="small-title">平台默认支付模式，不需要开通验证</span>
          </div>
          <div class="czbank">
            <a-checkbox checked disabled />
            <div class="czlogo">
              <img :src="ZS" alt="" />
            </div>
            <div class="hint">
              <p>支持国内大部分银行进行支付操作；</p>
              <span class="fw700">T+0</span>
              <span>即时到账：需要支付账号为浙商银行账号 </span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span class="fw700">T+1</span>
              <span>隔天/24小时后到账：其他银行</span>
            </div>
          </div>
        </div>
      </a-row>
      <!-- 余额支付 -->
      <a-row type="flex" justify="center">
        <div class="balance-pay gateway-pay">
          <div class="gateway-title">
            <span class="big-title">余额支付</span>
            <span class="small-title">需要进行银行信息鉴权，支持批量支付，非必选</span>
          </div>
          <div :class="!paObj.balanceStatus && 'pa-check'" @click="openPay" class="czbank pa-pay" style="cursor: pointer;">
            <a-checkbox :checked="paObj.balanceStatus == 1 || paObj.balanceStatus == 2" />
            <div class="czlogo">
              <img :src="PN" alt="" :style="!paObj.balanceStatus && 'filter: grayscale(1);'" />
            </div>
            <div class="hint">
              <span v-if="!paObj.balanceStatus" style="color:#AAA">未开通</span>
              <span v-if="paObj.balanceStatus == 1" style="color:#AAA">已开通</span>
              <a v-if="paObj.enableContinueBindCard" class="toAAA" @click="$refs.entityBind.toAAA()">继续鉴权</a>
              <b v-if="paObj.balanceStatus == 2">{{`绑定账户 ${this.$route.query.commercialName && ('| 账户名：' + this.$route.query.commercialName) || ''} | 账号：${str(paObj.bankAccount)}`}}</b>
            </div>
          </div>
          <div class="verification" v-show="verif && !paObj.enableContinueBindCard">
            <!-- 平安 -->
            <entityBind ref="entityBind" class="entityBind" @ok="paInfo"/>
          </div>
        </div>

      </a-row>
      <!-- 完成入驻 -->
      <div class="accomplish">
        <a-button type="primary" class="btn" @click="getMenu" icon="check-circle">
          完成入驻
        </a-button>
      </div>
    </div>
    <!-- 小额鉴权 -->
    <paAAA ref="AAA" />
  </div>
</template>



<script>
import { selectBanks } from '../../api/seller'
import bank from '@/utils/banks'
import entityBind from '@/components/paBind/entityBind'
import paAAA from '@/components/paBind/paAAA'
import ZS from '@/assets/images/u154.png'
import PN from '@/assets/images/u12672.png'
// import SearchSelect from '@/components/SearchSelect.vue'
import city from '@/utils/city'
import { branch } from '@/api/public'
import cookie from '@/utils/js-cookie.js'
import {paInfo, openSubAccountPurchaser} from '@/api/pa'
// var myreg =/^1[3-9][0-9]{9}$/
export default {
  components: { entityBind, paAAA },
  data() {
    return {
      ZS,
      PN,
      city,
      legalName: '', //法人姓名
      idCard: '', //法人身份证号
      bankAccount: '', //银行账号
      bankReservedMobile: '', //银行预留手机号
      bankSlip: [],
      cascaderValue: [], //  城市值
      bankNum: {
        cityCode: [],  // 市级编码
      },              //  获取银行行号参数
      bankName: '', // 开户行名称
      openBank: undefined, //开户行查询
      bankNumber: '', // 开户行行号
      Searchlist: { placeholder: '' },
      Bank: [], //银行列表
      defaultChecked: true,
      visible: false,
      confirmLoading: false,
      verif: false,
      choose: false, //是否使用已有平安银行
      paObj: {
        balance: 0,
        balanceStatus: 0,
        bankAccount: undefined,
        bankName: undefined,
        phone: undefined,
        enableContinueBindCard: false
      }
    };
  },
  mounted() {
    selectBanks().then((res) => {
      this.Bank = res.data
    })
    this.paInfo()
  },
  methods: {
    getMenu() {
      cookie.set('checkEntity', { entityCharacter: 'P', entityId: this.$route.query.entityId })
      window.location.href = `${window.location.origin}/buyercenter?entityId=${this.$route.query.entityId}`
    },
    str(val) {
      console.log('222',val);
        if (!val) return
        const suffix = val.substring(val.length - 4)
        const prefix = val.substring(0,5)
        console.log(val,prefix + '******' + suffix)
        return prefix + '*********' + suffix
    },
    paInfo () {
      paInfo({type: 'P', entityId: this.$route.query.entityId}).then(res => {
        if (res.code === 200) {
          const {balance,balanceStatus,bankAccount,bankName,phone,enableContinueBindCard} = res.data
          this.paObj = res.data
          this.paObj = {balance,balanceStatus,bankAccount,bankName,phone,enableContinueBindCard}
          if (this.paObj.balanceStatus === 1) {
            this.verif = true
          } else {
            this.verif = false
          }
        } else {
          this.$message.error(res.message)
        }
          
      })
    },
    openPay () {
      if (this.paObj.balanceStatus !== 0) return
       this.subAccountLoading = true
       const that = this
       this.$confirm({
         title: '是否开启余额支付',
         onOk() {
           return new Promise((resolve, reject) => {
             openSubAccountPurchaser({purchaserId: that.$route.query.entityId}).then(res => {
              if (res.code ===200) {
                  that.paInfo()
                  that.verif = true
                  resolve()
              } else {
                that.$message.error(res.message)
                reject()
              }
            })
           })
         }
       })
     },
    // 银行卡
    blur() {
      if (bank(this.bankAccount).bankName) {
        this.Bank.forEach((item) => {
          item.bankName === bank(this.bankAccount).bankName &&
            (this.openBank = item.bankCode)
        })
      } else if (!this.bankAccount) {
        this.$message.error('银行卡不能为空')
      } else {
        this.openBank = undefined
      }
    },
    // 选择银行
    BankChange(openCode) {
      this.openBank = openCode
      this.cascaderValue = []
    },
    SearchBankNum(e) {
      const config = {
        cityCode: this.cascaderValue[1],
        keywords: e,
        bankCode: this.openBank
      }
      branch(config).then(res => {
        this.bankSlip = res.data.branchList
      })
    },
    // 获取银行民称和银行卡号
    getBanknum(e) {
      this.bankName = e.branchName
      this.bankNumber = e.branchNo
    },
    // 开户行名称
    cascader(value) {
      this.bankNum.cityCode = value[1]
    },
    handleOk() {

    },
  }
}
</script>

<style lang="less" scoped>
.bankinformation {
  margin-bottom: 100px;
  max-width: 1440px;
  min-width: 990px;
  @media screen and (max-width: 1440px) {
    padding: 0 15px;
  }
  margin: 20px auto;
  .mainbody {
    height: 100%;
    background-color: #ffffff;
    padding: 0 0 40px 0;
    .header-title {
      padding: 30px;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.847058823529412);
      font-weight: 650;
    }
    .header-text {
      padding: 0 0 0 46px;
      font-size: 16px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.647058823529412);
    }

    .gateway-pay {
      width: 1200px;
      padding: 45px 0 0 45px;
      .czbank {
        max-width: 990px;
        height: 80px;
        margin: 20px;
        display: flex;
        padding: 0 0 0 20px;
        align-items: center;
        border: 2px solid rgb(246, 176, 30);
        border-radius: 5px;
        background-color: rgb(217, 217, 217);
        .czlogo {
          margin-left: 10px;
          width: 160px;
          height: 50px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .hint {
          font-size: 14px;
          padding: 0 0 0 50px;
          p {
            padding: 5px 0;
          }
          .fw700 {
            font-weight: 700;
          }
        }
      }
      .pa-pay {
        margin: 20px 20px 0 20px;
      }
      .pa-check {
        border:none;
        margin-bottom:0;
        border-radius:10px 10px 0 0
      }
      .pa-style {
        border: 2px solid #f6b01e;
        border-radius: 5px;
      }
    }

    .accomplish {
      margin-top: 100px;
      text-align: center;
    }
    .big-title {
      font-size: 24px;
      font-weight: 700;
      font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
      color: #666666;
      padding: 0 20px 0 0;
    }
    .small-title {
      font-size: 14px;
      color: #aaaaaa;
    }
  }
}
.btn {
  font-size: 18px;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: rgb(109, 186, 68);
}
.verification {
  position: relative;
  margin: 20px;
  margin-top: 0;
  max-width: 990px;
  background-color: rgb(241, 242, 242);
  border-radius: 0 0 10px 10px;
  padding: 30px 150px 32px 70px;
  .red {
    color: red;
  }
  p {
    padding: 20px 0 0 0;
    font-size: 14px;
  }
  .text-head {
    padding-right: 10px;
    display: inline-block;
    width: 110px;
    text-align: right;
  }
  .info-input3 {
    width: 400px;
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.247058823529412);
    border-radius: 5px;
    padding: 0 0 0 10px;
  }
  .USE {
    padding: 40px 0 0 0;
    .check {
      padding-left: 50px;
      font-size: 16px;
      .a-checkbox {
        border-color: #ccc;
      }
    }
  }
}
.SearchSelect {
  display: inline-block;
  width: 330px;
}
.smallMon {
  font-size: 24px;
  color: #000;
  font-weight: 650;
}
.prove {
  position: absolute;
  bottom: 20px;
  left: 42.5%;
}
.toAAA {
  margin-left: 16px;
  &:hover {
    text-decoration: underline;
  }
}
/deep/.ant-checkbox-wrapper-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(2) translate(-50%, -50%);
  border-color: rgb(246, 176, 30) !important;
}
/deep/.ant-checkbox-inner {
  border-color: rgb(170, 170, 170) !important;
  background-color: #fff;
  &:hover {
    border-color: none;
  }
}
/deep/.ant-checkbox-checked::after {
  border-color: none;
}
/deep/ .ant-modal-footer {
  border: none;
}
</style>